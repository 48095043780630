import logo from './images/logo.png';
import './App.css';
import { useEffect, useState } from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';

const HOTDOG_TIME = '2022-03-26T15:00:00.000Z';
const hotdogTime = new Date(HOTDOG_TIME);

function App() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setNow(new Date());
      },
      1000,
    );
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="background">
      <div className="container">
        <img className="logo" src={logo} alt="Destiny 2 The Witch Queen" />
        <h1 className="time-until">TIME UNTIL 🌭🌭🌭:</h1>
        <h2>{formatDuration(intervalToDuration({ start: now, end: hotdogTime }))}</h2>
      </div>
    </div>
  );
}

export default App;
